// Preload any scripts that require global scope (jQuery...)

import jquery from 'jquery';
import lodash from 'lodash';
import FroalaEditor from 'froala-editor';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/languages/es.js';
import 'froala-editor/js/languages/fr.js';
import 'froala-editor/js/languages/it.js';
import 'froala-editor/js/languages/ko.js';
import 'froala-editor/js/languages/pt_br.js';
import 'froala-editor/js/languages/sv.js';
import 'jquery-validation';
import "jquery-validation/dist/additional-methods.js"

window.jQuery = jquery;
window.$ = jquery;

const _ = lodash;
window._ = _;

window.FroalaEditor = FroalaEditor;

// Require
const Pusher = require('pusher-js');

window.Pusher = Pusher;

const moment = require('moment');

window.moment = moment;

// Match media screen widths
const mediaLg = window.matchMedia('(min-width: 1024px) and (max-width: 1279px)');
const mediaXl = window.matchMedia('(min-width: 1280px) and (max-width: 1535px)');
const media2Xl = window.matchMedia('(min-width: 1536px)');

window.isScreenLg = mediaLg.matches;
window.isScreenXl = mediaXl.matches;
window.isScreen2Xl = media2Xl.matches;

mediaLg.addEventListener('change', event => {
  window.isScreenLg = event.matches;
});

mediaXl.addEventListener('change', event => {
  window.isScreenXl = event.matches;
});

media2Xl.addEventListener('change', event => {
  window.isScreen2Xl = event.matches;
});
